<template>
  <div class="page_bg">
    <!-- 顶部返回 -->
    <div class="express_top flex-c-s-s">
      <div class="flex-r-s-s">
        <div class="flex-r-c-c" @click="backPath">
          <van-icon name="arrow-left" size="0.5rem" />
          <div>返回</div>
        </div>
        <div class="express_name">物流详情</div>
      </div>
      <!-- 多包裹 -->
      <div v-if="packetList.length > 1" class="express_List">
        <div class="express_List_title">您的订单已拆分为 {{ packetList.length }}个包裹</div>
        <div class="express_List_tabs flex-r-s-s">
          <div v-for="(tab,tab_index) in packetList" :key="tab_index"
               :class="tabed === tab_index+1 ? 'express_List_tab tab_avtived':'express_List_tab'"
               @click="changetab(tab,tab_index)"
          >
            包裹{{ tab_index+1 }}
          </div>
        </div>
      </div>
    </div>
    <template v-if="packetList.length>0">
      <!-- 物流信息 -->
      <div class="express_info" :style="{ marginTop: packetList.length > 1 ? '-0.9rem' :'-3.6rem' }">
        <div class="flex-r-sb-c">
          <div class="flex-r-c-c">
            <img style="width:0.48rem;height:0.48rem;margin-right:0.1rem" src="./../../assets/express_icon.png">
            <div id="quit_express_btn" class="express_name" @click="copyExpress(packetList[choosed].expressNo)">
              {{ packetList[choosed].expressName }}：{{ packetList[choosed].expressNo }} </div>
          </div>
          <div class="express_open flex-r-c-c" @click="isOpen = !isOpen">
            <div>{{ isOpen ? '收起详情':'物流详情' }}</div>
            <van-icon v-if="isOpen" name="arrow-up" size="0.4rem" />
            <van-icon v-else name="arrow-down" size="0.4rem" />
          </div>
        </div>
        <van-divider />
        <div v-if="isOpen" style="max-height: 8.88rem;overflow-y: scroll;" @click="isOpen = !isOpen">
          <van-steps direction="vertical" :active="0" active-color="#0767AB" active-icon="map-marked"
                     inactive-icon="checked"
          >
            <van-step v-for="(step_item1,step_index1) in expressStep" :key="step_index1" class="express_step">
              <div class="step_status" />
              <div class="step_msg">{{ step_item1.acceptStation }}</div>
              <div class="step_time">{{ step_item1.acceptTime }}</div>
            </van-step>
          </van-steps>
        </div>
        <div v-else>
          <van-steps direction="vertical" :active="0" active-color="#0767AB" active-icon="map-marked"
                     inactive-icon="checked"
          >
            <van-step v-for="(step_item,step_index) in expressStep1_2" :key="step_index" class="express_step">
              <div class="step_status" />
              <div class="step_msg">{{ step_item.acceptStation }}</div>
              <div class="step_time">{{ step_item.acceptTime }}</div>
            </van-step>
          </van-steps>
        </div>
      </div>
      <!-- 商品信息 -->
      <div class="express_pro_list">
        <div v-for="(pro_item,pro_index) in packetList[choosed].list" :key="pro_index" class="pro_list_item ">
          <proCard04 :info="pro_item" :kind="99" />
        </div>
      </div>
      <br>
    </template>
    <!-- 空状态 -->
    <template v-else>
      <div class="express_empty">
        <van-image width="4.8rem" height="3.2rem" style="margin-top:4rem"
                   src="https://oss.xiaoyi120.com/shop2.0/order/express_empty.png"
        />
        <div>快递小哥暂未录入物流信息...</div>
      </div>
    </template>
  </div>
</template>

<script>
import './index.scss'
import Clipboard from 'clipboard'
import { mallOrder_express, selectExpressNo } from '@/api/order'
import proCard04 from '@/components/pro_card_04'
export default {
  components: { proCard04 },
  data() {
    return {
      orderId: '',
      choosed: 0,
      tabed: 1,
      isOpen: true,
      packetList: [],
      showproList: [],
      proList: [],
      expressStep: [],
      expressStep1_2: []
    }
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      window.sessionStorage.setItem('lasterRouter', from.path)
    })
  },
  created() {
    this.orderId = this.$route.query.orderId
    this.getExpressList()
  },
  methods: {
    // 获取列表
    getExpressList() {
      mallOrder_express(this.orderId).then(res => {
        this.packetList = res.data
        console.log(this.packetList, this.packetList.length)
        this.choosePacket(this.packetList, 0)
      })
    },
    choosePacket(item, index) {
      console.log('item', item)
      console.log('index', index)
      this.choosed = index
      // const phone = item[index].purchasingAgentPhone.substring(7, 11)
      selectExpressNo({
        expressCode: item[index].expressCode,
        expressNo: item[index].expressNo,
        mobile: item[index].purchasingAgentPhone
      }).then(res => {
        this.expressStep = res.data.traces
        if (res.data.traces.length > 1) { this.expressStep1_2 = [res.data.traces[0], res.data.traces[res.data.traces.length - 1]] }
      })
    },
    backPath() { window.history.back() },
    changetab(item, index) {
      this.tabed = index + 1
      this.choosePacket(this.packetList, index)
    },
    // 复制地址
    copyExpress(no) {
      var that = this
      var data = no
      const clipboard = new Clipboard('#quit_express_btn', {
        text: function () { return data }
      })
      clipboard.on('success', function (e) {
        e.clearSelection() // 清除选中的文字的选择状态
        that.$toast({ message: '复制成功', duration: 1 * 1000 })
      })

      clipboard.on('error', function (e) {
        console.error('Action:', e.action)
        console.error('Trigger:', e.trigger)
      })
    }
  }
}
</script>

<style>
.express_empty {
  padding-bottom: 7rem;
}
.express_empty div {
  font-size: 0.37rem;
  color: #999999;
  margin-top: 0.59rem;
}
</style>
